import * as React from 'react'
import { useMutation } from '@apollo/react-hooks'
import loadable from '@loadable/component'
import gql from 'graphql-tag'
import { vsprintf } from 'sprintf-js'

import {
  EnterpriseContext,
  i18n,
  RateLimitBucket,
  useCaptcha,
  useLogger,
  useRequestConfig,
  useSiteConfig,
  useSiteDefinition,
  useTransmit,
} from '@thg-commerce/enterprise-core'
import {
  getColumboEventData,
  useBackendEventNormaliser,
} from '@thg-commerce/enterprise-metrics'
import { pushToDataLayer } from '@thg-commerce/enterprise-metrics/src/data_layer'
import { MarketingType } from '@thg-commerce/enterprise-network/src/generated/graphql'
import {
  EnterpriseThemeInterface,
  spacing,
  styled,
} from '@thg-commerce/enterprise-theme'
import { FormItem, Input } from '@thg-commerce/gravity-elements'
import { DynamicForm } from '@thg-commerce/gravity-patterns'

import { modalCallbacks } from './dataTracking'
import { EmailEngagementModalProps, FormStatus } from './EmailEngagementModal'
import {
  Content,
  FormContainer,
  ImageContainer,
  LegalText,
  ListContainer,
  ListItem,
  MessageContainer,
  PrivacyPolicy,
  StyledPicture,
  SubmitButton,
  SubmittedText,
  SubmittedTitle,
  SuccessButton,
  TextContainer,
  Title,
} from './styles'

const Tick = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Tick'),
  {
    ssr: true,
    fallback: (
      <div style={{ width: '22px', height: '14px', display: 'inline-block' }} />
    ),
  },
)

interface SubmitValues {
  emailAddress: string
}

export const SIGNUP_FOR_MARKETING_MUTATION = gql`
  mutation signUpForMarketing($input: SignUpForMarketingInput!) {
    signUpForMarketing(input: $input) @client
  }
`

const TickIcon = styled(Tick)`
  margin-right: ${spacing(1)};
  stroke: ${(props) => props.theme.colors.palette.greys.darker};
  display: inline-block;
  width: 14px;
  height: 14px;
`

export const SuccessTick = styled(Tick)`
  width: 35px;
  height: 35px;
  stroke: ${(props) => props.theme.colors.palette.brand.base};
  display: block;
  margin: auto;
`

export const EmailEngagementModalContent = (
  props: EmailEngagementModalProps & {
    theme: EnterpriseThemeInterface
    i18nText: any
  },
) => {
  const logger = useLogger()
  const { csrf } = useRequestConfig()
  const captcha = useCaptcha({
    bucket: RateLimitBucket.MARKETING_MATERIAL_SIGN_UP,
  })
  const transmit = useTransmit()
  const EnterpriseCtx = React.useContext(EnterpriseContext)
  const { defaultLocale, subsite, siteId, channel } = useSiteDefinition()
  const propertyArgs = {
    defaultLocale,
    subsite,
    siteId,
    channel,
    customerLocation: EnterpriseCtx.currentLocation,
  }
  const normaliseBackendEvent = useBackendEventNormaliser()

  const [addToNewsletter] = useMutation(SIGNUP_FOR_MARKETING_MUTATION)

  const { showTermsConditionTextOnEmailEngagement } = useSiteConfig()

  const bulletText = props.bulletText.map(
    (item, index) =>
      item && (
        <ListItem key={index}>
          <TickIcon />
          {item}
        </ListItem>
      ),
  )

  const buildTransmitPayLoad = (errors?: { message: string }[]) => ({
    type: 'newsletter_signup',
    payload: normaliseBackendEvent({
      requestData: {
        start_timestamp: Date.now(),
        duration_ms: 0,
        url: window.location.href,
      },
      eventData: {
        type: 'newsletter_signup',
        subtype: 'email_sign_up_modal',
      },
      ...(errors && {
        errors: errors.map((e) => ({
          label: e?.message || 'no message',
          type: 'newsletter_signup_error',
        })),
      }),
    }),
  })

  const signUpForMarketing = (
    values: SubmitValues,
    captchaResponse?: string,
  ) => {
    try {
      addToNewsletter({
        variables: {
          input: {
            type: MarketingType.Email,
            contactDetails: values.emailAddress,
            auditData: {
              messageShown: props.bulletText.join('\r\n'),
              formIdentifier: 'MARKETING_MATERIAL_SIGN_UP',
              formLocation: 'EmailEngagementModal',
            },
          },
        },
        context: {
          headers: {
            'X-Captcha-Type': captcha.type,
            'X-Captcha-Response': captchaResponse,
          },
        },
      }).then(({ data, errors }) => {
        if (
          data.signUpForMarketing &&
          !data.signUpForMarketing.errors &&
          !errors
        ) {
          pushToDataLayer({
            type: 'customEvent',
            eventData: {
              eventCategory: 'newsletter',
              eventAction: 'successful sign-up',
              eventLabel: '',
              eventPage: '',
            },
          })
          transmit(buildTransmitPayLoad())
          props.statusHandler(FormStatus.SUCCESSFUL)
        } else {
          logger.error('signupForMarketing error in Email Engagement Modal', {
            data,
            errors,
            'X-Captcha-Type': captcha.type,
            'X-Captcha-Response': captchaResponse,
          })
          props.statusHandler(FormStatus.FAILURE)
          transmit(
            buildTransmitPayLoad(
              data?.signUpForMarketing?.errors || [
                { message: 'no error data' },
              ],
            ),
          )
        }
      })
    } catch (err) {
      if (err instanceof Error) {
        logger.debug(err.message, {
          reqId: 'Email Engagment Modal',
          'X-Captcha-Type': captcha.type,
          'X-Captcha-Response': captchaResponse,
        })
      }
      props.statusHandler(FormStatus.FAILURE)
    }
  }

  let sentFocusEvent = false
  const handleFocus = () => {
    if (sentFocusEvent) {
      return
    }

    sentFocusEvent = true
    transmit({
      type: 'columbo',
      payload: getColumboEventData({
        propertyArgs,
        argumentsObj: {
          '0': 'Accessibility',
          '1': 'Focused',
          '2': 'EmailEngagementModalInput',
        },
        requestArgs: {
          client_timestamp: Math.round(Date.now() / 1000),
          url: window.location.href,
        },
        eventData: {
          type: 'focus',
          subtype: 'internationalOverlay',
          contents: [],
        },
        nonce: EnterpriseCtx.metricNonce,
      }),
    })
  }

  const onSubmitHandler = (fields: SubmitValues) => {
    captcha.submit((response) => signUpForMarketing(fields, response))
  }

  const i18nText = {
    additionalLink1: i18n(
      'account.newaccount.createaccountform.termsandconditions.link.additional.pt1',
    ),
    additionalLink2: i18n(
      'account.newaccount.createaccountform.termsandconditions.link.additional.pt2',
    ),
    pt6Text: i18n(
      'account.newaccount.createaccountform.termsandconditions.text.pt6',
    ),
    pt8Text: i18n(
      'account.newaccount.createaccountform.termsandconditions.text.pt8',
    ),
  }

  const pt6Link =
    i18nText.pt6Text && i18nText.additionalLink1
      ? `<a href="${i18nText.additionalLink1}" target="_blank" rel="noopener noreferrer">${i18nText.pt6Text}</a>`
      : ''
  const pt8Link =
    i18nText.pt8Text && i18nText.additionalLink2
      ? `<a href="${i18nText.additionalLink2}" target="_blank" rel="noopener noreferrer">${i18nText.pt8Text}</a>`
      : ''

  const legalText = vsprintf(
    '%s <a href="/%s" target=”_blank” rel=”noopener noreferrer”>%s</a> %s <a href="/%s" target=”_blank” rel=”noopener noreferrer”>%s</a> %s %s %s %s',
    [
      i18n('account.newaccount.createaccountform.termsandconditions.text.pt1'),
      i18n(
        'account.newaccount.createaccountform.termsandconditions.link.terms',
      ),
      i18n('account.newaccount.createaccountform.termsandconditions.text.pt2'),
      i18n('account.newaccount.createaccountform.termsandconditions.text.pt3'),
      i18n(
        'account.newaccount.createaccountform.termsandconditions.link.privacy',
      ),
      i18n('account.newaccount.createaccountform.termsandconditions.text.pt4'),
      i18n('account.newaccount.createaccountform.termsandconditions.text.pt5'),
      pt6Link,
      i18n('account.newaccount.createaccountform.termsandconditions.text.pt7'),
      pt8Link,
    ],
  )

  return (
    <Content>
      <ImageContainer>
        <StyledPicture {...props.picture} fetchPriority="high" />
      </ImageContainer>
      {props.status === FormStatus.UNSUBMITTED ? (
        <FormContainer>
          <ListContainer>
            <Title>{props.modalTitle}</Title>
            <TextContainer>{bulletText}</TextContainer>
          </ListContainer>
          <React.Fragment>
            <DynamicForm
              csrf={csrf}
              onSubmit={onSubmitHandler}
              data-testid="form-guest-checkout"
              fields={[
                {
                  fieldName: 'emailAddress',
                  renderField: (
                    ref: React.RefObject<HTMLInputElement>,
                    key: string,
                  ) => (
                    <FormItem bindref={ref} disableSpacing key={key}>
                      <Input
                        label={props.i18nText.inputLabel}
                        required
                        type="email"
                        autocomplete="email"
                        onFocus={handleFocus}
                      />
                    </FormItem>
                  ),
                },
              ]}
            >
              {captcha.captcha}
              <SubmitButton
                type="submit"
                emphasis="high"
                data-testid="button-submit-newletter-signup"
              >
                {props.i18nText.submitButton}
              </SubmitButton>
            </DynamicForm>
            {showTermsConditionTextOnEmailEngagement ? (
              <LegalText content={legalText} />
            ) : (
              <PrivacyPolicy
                emphasis="low"
                renderedAs="a"
                href={`/${props.i18nText.privacyPolicyLink}`}
              >
                {props.i18nText.privacyPolicyText}
              </PrivacyPolicy>
            )}
          </React.Fragment>
        </FormContainer>
      ) : props.status === FormStatus.SUCCESSFUL ? (
        <FormContainer>
          <MessageContainer>
            <SuccessTick viewBox="0 0 14 14" />
            <SubmittedTitle>{props.i18nText.successTitle}</SubmittedTitle>
            <SubmittedText>{props.i18nText.successText}</SubmittedText>
            <SuccessButton
              onClick={() => {
                props.closeHandler()
                modalCallbacks.continueButtonHandler()
              }}
              data-testid="continue-button-newsletter"
            >
              {props.i18nText.continueButton}
            </SuccessButton>
          </MessageContainer>
        </FormContainer>
      ) : (
        <FormContainer>
          <MessageContainer>
            <SubmittedTitle>{props.i18nText.errorTitle}</SubmittedTitle>
            <SubmittedText>{props.i18nText.errorText}</SubmittedText>
            <SuccessButton
              emphasis="low"
              onClick={() => {
                props.statusHandler(FormStatus.UNSUBMITTED)
              }}
              data-testid="retry-button-newsletter"
            >
              {props.i18nText.tryAgainButton}
            </SuccessButton>
          </MessageContainer>
        </FormContainer>
      )}
    </Content>
  )
}
